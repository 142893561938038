<!--过渡页面-->
<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>

</style>
